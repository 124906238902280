/** 会员端小程序生成二维码配置 */
export const MEMBER_QR_CODE_CONFIG = {
  development: {
    sku: {
      value: "https://dev-member-purchase.hbxinfadi.com/#/member/qrcode/sku",
      label: "商品详情",
      env: "开发环境",
      envCode: "develop",
    },
    cate: {
      value: "https://dev-member-purchase.hbxinfadi.com/#/member/qrcode/cate",
      label: "品类",
      env: "开发环境",
      envCode: "develop",
    },
  },
  sit: {
    sku: {
      value: "https://test-member-purchase.hbxinfadi.com/#/member/qrcode/sku",
      label: "商品详情",
      env: "测试环境",
      envCode: "trial",
    },
    cate: {
      value: "https://test-member-purchase.hbxinfadi.com/#/member/qrcode/cate",
      label: "品类",
      env: "测试环境",
      envCode: "trial",
    },
  },
  production: {
    sku: {
      value: "https://member-purchase.hbxinfadi.com/#/member/qrcode/sku",
      label: "商品详情",
      env: "生产环境",
      envCode: "release",
    },
    cate: {
      value: "https://member-purchase.hbxinfadi.com/#/member/qrcode/cate",
      label: "品类",
      env: "生产环境",
      envCode: "release",
    },
  },
};

/** 小程序二维码，界面枚举 */
export const MINI_APP_PR_CODE_PAGE = {
  sku: {
    value: 1,
    label: "商品详情",
  },
  cate: {
    value: 2,
    label: "品类",
  },
  zone: {
    value: 3,
    label: "专区",
  },
};

/** 登录密码类别 */
export const LOGIN_PASSWORD = {
  0: {
    value: 0,
    label: "正常验证通过",
  },
  1: {
    value: 1,
    label: "弱密码",
  },
  2: {
    value: 2,
    label: "一次性密码",
  },
};
